exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-event-tsx": () => import("./../../../src/pages/event.tsx" /* webpackChunkName: "component---src-pages-event-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-agenda-tsx": () => import("./../../../src/pages/new-agenda.tsx" /* webpackChunkName: "component---src-pages-new-agenda-tsx" */),
  "component---src-pages-new-event-tsx": () => import("./../../../src/pages/new-event.tsx" /* webpackChunkName: "component---src-pages-new-event-tsx" */),
  "component---src-pages-post-tsx": () => import("./../../../src/pages/post.tsx" /* webpackChunkName: "component---src-pages-post-tsx" */),
  "component---src-pages-splash-tsx": () => import("./../../../src/pages/splash.tsx" /* webpackChunkName: "component---src-pages-splash-tsx" */)
}

